<template>
        <defaultSec :title="'用户查询'">
<!--            <keep-alive>-->
<!--                <router-view></router-view>-->
                <el-form :inline="true" :model="formParam" class="demo-form-inline select-wrap" size="mini">
                    <el-form-item label="昵称">
                        <el-input v-model="formParam.nickName" placeholder="请输入用户昵称" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="手机">
                        <el-input v-model="formParam.mobile" placeholder="请输入用户手机" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="openId">
                        <el-input v-model="formParam.openId" placeholder="请输入openId" clearable></el-input>
                    </el-form-item>

                    <el-form-item label="customerId">
                        <el-input v-model="formParam.customerId" placeholder="请输入customerId" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-checkbox v-model="formParam.isOnLine" checked true-label="1" false-label="0" >在线</el-checkbox>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="initList">查询</el-button>
                        <el-button type="primary" @click="validGiftCard">恢复礼品卡</el-button>
                        <el-button type="primary" @click="showAddBlack">添加黑名单</el-button>
                    </el-form-item>
                </el-form>
<!--            </keep-alive>-->
            <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
                <el-table :data="tableList" border size="mini">
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column label="头像">
                        <template slot-scope="scope">
                            <el-avatar size="small" :src="scope.row.headImageUrl"></el-avatar>
                        </template>
                    </el-table-column>
                    <el-table-column prop="nickName" label="昵称"></el-table-column>
                    <el-table-column prop="mobile" label="手机号"></el-table-column>
                    <el-table-column prop="consultTime" label="最新咨询时间"></el-table-column>

                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="danger" plain size="mini" @click="toDetail(scope.row.customerId)">查看</el-button>
<!--                            <el-button type="danger" plain size="mini" @click="addMama(scope.row.customerId,'1435791086556811265')">酸奶老用户</el-button>-->
<!--                            <el-button type="danger" plain size="mini" @click="addMama(scope.row.customerId,'1426085731165016065')">牛奶老用户</el-button>-->
<!--                            <el-button type="danger" plain size="mini" @click="addMama(scope.row.customerId,'1435813276983496705')">乳酪老用户</el-button>-->
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 黑名单 -->
                <el-dialog title="添加黑名单" :visible.sync="addBlackView" :before-close="handleClose">
                    <el-form ref="form" :model="formBlack" label-width="80px" class="draw-box">
                        <el-form-item label="手机号">
                            <el-input v-model="formBlack.mobile" size="mini"></el-input>
                        </el-form-item>
                        <el-form-item label="订单号">
                            <el-input v-model="formBlack.orderNo" size="mini"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="addBlack">确定</el-button>
                        </el-form-item>
                    </el-form>
                </el-dialog>
            </tablePagination>
        </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    export default {
        name: "userList",/*此处的name必须有且与include中的一致*/
        data() {
            return {
                addBlackView:false,
                formBlack:{
                    mobile:'',
                    orderNo:''
                },
                formParam:{
                    pageSize:15,
                    currentPage:1,
                    isOnLine:1,
                    nickName:'',
                    mobile:'',
                    openId:'',
                    customerId:'',
                },
                tableList:[],
                total:0
            }
        },
        components:{
            defaultSec,tablePagination
        },
        mounted(){
            this.initList()
        },
        activated(){
            console.log("nick:"+this.formParam.nickName);
        },
        methods: {
            initList() {
                this.$apiGet('lechun-customers/customer/getCustomerList',this.formParam).then(res => {
                    console.log(res)
                    this.total = res.total;
                    this.tableList = res.list

                })
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.initList()
            },
            toDetail(id){
                this.$router.push({path:'/sysrole/:id',name:'userDetail',params:{id:id}})
            },
            addMama(id,bindcode){
                this.$apiGet('lechun-csms/customer/addMama',{'customerId':id,'bindcode':bindcode}).then(res => {
                    console.log(res);
                    this.$message({
                        showClose: true,
                        message: '成功',
                        type: 'success'
                    });
                })
            },
            validGiftCard(){
                this.$prompt('请输入卡号', '恢复礼品卡', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputType: 'text',
                }).then(({ value }) => {
                    this.$apiGet('lechun-balance/giftCard/invalidCard',{'cardNo': value,'cardState':0}).then(res=>{
                        this.$message({
                            message: '恢复成功',
                            type: 'success'
                        });
                    });
                }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '取消输入'
                // });
                });
            },
            showAddBlack(){
                this.addBlackView = true;
            },
            addBlack(){
                if(this.formBlack.mobile == '' && this.formBlack.orderNo == ''){
                    this.$notify({
                        title: '警告',
                        message: '手机号和订单号不能同时为空',
                        type: 'warning'
                    });
                    return false
                }
                this.$apiGet('lechun-csms/customer/addBlack',this.formBlack).then(res=>{
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    });
                });
            },
            handleClose() {
                this.formBlack = {
                    mobile:'',
                    orderNo:''
                };
                this.addBlackView = false;
            },
        }
    }
</script>


<style scoped>

</style>